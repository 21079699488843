import Bugsnag from '@bugsnag/js';

// NOTE: jsのシンタックスエラーも通知させる為にエントリーポイントを別にしている

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const options = process.env.RAILS_ENV === 'test' ? { logger: null } : {};

const bugsnagClient = Bugsnag.start({
  apiKey: '414df220035fddda0948225550a1fe19',
  releaseStage: process.env.RAILS_ENV,
  notifyReleaseStages: ['staging', 'production'],
  ...options,
});
